.footer {
  margin-top: 35px;
  padding: 20px;
  border-top: 3px solid #31473a;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-container {
  margin-bottom: 10px;
}

.contact-info {
  margin-bottom: 20px;
}

.label {
  font-weight: 500;
  display: block;
}

.link {
  color: #31473a;
  text-decoration: none;
}

.link:hover {
  font-weight: 600;
}

.copyright {
  font-size: 13px;
  margin-top: 15px;
}

@media screen and (min-width: 768px) {
  .contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    margin-top: 20px;
  }

  .contact-info {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .label {
    display: inline-block;
    margin-right: 10px;
  }

  .label,
  .detail {
    font-size: 18px;
  }

  .contribution-info {
    margin-top: 20px;
  }

  .copyright {
    font-size: 15px;
    margin-top: 20px;
  }
}

@media screen and (min-width: 1000px) {
  .contribution-info {
    padding-right: 55px;
  }

  .copyright {
    padding-right: 40px;
    margin-top: 40px;
  }
}
