/* Mobile-first styles */
#home {
  padding: 10px;
  margin: 30px auto;
  color: #283e32;
  text-shadow: -2px 4px 8px rgba(0, 0, 0, 0.1);
}

.home-profile {
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
}

.home-profile h2 {
  font-style: italic;
}

.home-profile p {
  padding: 0 10px;
  font-size: 17px;
  font-weight: 500;
}

.home-image-grid {
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 6px;
}

.home-image-item {
  background: radial-gradient(circle, #ffffff, #15333c45 70%, #010e1245);
}

.home-image-item img {
  width: 100%;
  max-width: 138px;
  height: 100%;
  border: 2px solid #283e32;
  border-radius: 3px;
  box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Media query for medium screens */
@media screen and (min-width: 576px) {
  #home {
    padding: 0 12px;
  }
  .home-profile {
    padding: 30px;
    font-size: 22px;
  }

  .home-profile p {
    font-size: 18px;
  }
}

/* Media query for large screens */
@media screen and (min-width: 768px) {
  .home-profile {
    width: 80%;
  }

  .home-profile p {
    font-size: 20px;
  }
}

/* Media query for extra-large screens */
@media screen and (min-width: 1100px) {
  #home {
    display: flex;
    gap: 40px;
    margin-top: 50px;
  }

  .home-profile {
    width: 55%;
    gap: 40px;
    padding: 0;
    margin-top: 60px;
  }

  .home-profile p {
    font-size: 22px;
  }

  .home-image-grid {
    width: 45%;
    margin: 40px auto;
  }
}
